import 'babel-polyfill'
import 'js/polyfills/customEvent'
import 'js/polyfills/closest'
import 'events-polyfill'
import objectFitImages from 'object-fit-images'

// =utils
import 'js/utils/fontFaceObserver'
import 'js/utils/breakpoints'
import 'js/utils/detectTouch'
//import 'js/utils/in-view'
import 'js/utils/revealBlocks'
import 'js/utils/equalHeight'

import 'lazysizes'

// debug mode
if (document.querySelector('body').classList.contains('-debug')) {
  import(/* webpackChunkName: "debugger" */ 'js/utils/debugger')
}

// handles menu loading bug on firefox
const loadNav = Array.from(document.querySelectorAll('.js-load-menu-hide'))
loadNav.forEach(elem => {
  document.addEventListener('DOMContentLoaded', () => {
    elem.classList.remove('-hidden-menu')
  })
})

//===============================================================/
//  =components
//===============================================================/

// if (document.querySelector('.cookie-consent')) {
//   import(/* webpackChunkName: "compCookieConsent" */ 'js/components/cookieConsent')
// }

if (document.querySelector('.js-animated-cards')) {
  import(/* webpackChunkName: "animtedCards" */ 'js/components/animated-cards')
}

if (document.querySelector('.js-accordion')) {
  import(/* webpackChunkName: "accordion" */ 'js/components/accordion')
}

if (document.querySelector('.js-region-selector')) {
  import(
    /* webpackChunkName: "regionSelector" */ 'js/components/region-selector'
  )
}

if (document.querySelector('.js-round-links')) {
  import(/* webpackChunkName: "roundLinks" */ 'js/components/round-links')
}

if (document.querySelector('.js-product-card-slider')) {
  import(
    /* webpackChunkName: "productCardSlider" */ 'js/components/product-card-slider'
  )
}

if (document.querySelector('.js-search-field')) {
  import(/* webpackChunkName: "searchField" */ 'js/components/search-field')
}

if (document.querySelector('.js-search-results')) {
  import(/* webpackChunkName: "searchResults" */ 'js/components/search-results')
}

if (document.querySelector('.form-filter__wrapper')) {
  import(/* webpackChunkName: "customFilter" */ 'js/components/custom-filter')
}

if (document.querySelector('.js-form-modal')) {
  import(/* webpackChunkName: "formShortcode" */ 'js/components/form-shortcode')
}

if (document.querySelector('.js-video-link')) {
  import(/* webpackChunkName: "videoLink" */ 'js/components/video-link')
}

if (document.querySelector('.js-hero-down')) {
  import(/* webpackChunkName: "heroAction" */ 'js/components/hero-action')
}

//===============================================================/
//  =blocks
//===============================================================/

if (document.querySelector('.js-banner-carousel')) {
  import(/* webpackChunkName: "bannerCarousel" */ 'js/blocks/banner')
}

if (document.querySelector('.js-image-content')) {
  import(/* webpackChunkName: "imageContent" */ 'js/blocks/image-content')
}

if (document.querySelector('.-js-linechecker-form')) {
  import(
    /* webpackChunkName: "lineCheckerForm" */ 'js/blocks/line-checker-form'
  )
}

if (document.querySelector('.-js-tabs')) {
  import(/* webpackChunkName: "tabContent" */ 'js/blocks/tab-content')
}

if (document.querySelector('.-js-call-charges')) {
  import(/* webpackChunkName: "callCharges" */ 'js/blocks/call-charges')
}

if (document.querySelector('.-js-roaming-charges')) {
  import(/* webpackChunkName: "roamingCharges" */ 'js/blocks/roaming-charges')
}

if (document.querySelector('.-js-table-block')) {
  import(/* webpackChunkName: "tableBlock" */ 'js/blocks/table-block')
}

if (document.querySelector('.js-content-media-play')) {
  import(/* webpackChunkName: "contentMedia" */ 'js/components/content-media')
}

if (document.querySelector('.js-content-large-image')) {
  import(
    /* webpackChunkName: "contentLargeImage" */ 'js/blocks/content-large-image'
  )
}

if (document.querySelector('.js-downloads-reveal')) {
  import(/* webpackChunkName: "downloadsReveal" */ 'js/blocks/downloads')
}

if (document.querySelector('.js-icon-carousel')) {
  import(/* webpackChunkName: "iconsWithText" */ 'js/blocks/icons-with-text')
}

if (document.querySelector('.js-hero-video')) {
  import(/* webpackChunkName: "heroVideo" */ 'js/blocks/hero-video')
}

//===============================================================/
//  =layout
//===============================================================/

if (document.querySelector('.site-nav')) {
  import(/* webpackChunkName: "layoutNav" */ 'js/layouts/nav')
}

//===============================================================/
//  =pages
//===============================================================/

// if (document.querySelector('.page-specific')) {
//   import(/* webpackChunkName: "pageNameHere" */ 'js/pages/pageNameHere')
// }

objectFitImages()
